import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class DecowoodService {

  lang: string;

  constructor(private http: HttpClient, private language: LanguageService) {
    console.log('Decowood Service Listo');
  }

  getQuery(query: string, api_number: number) {
    let url = '';
    if (api_number == 1) {
      url = `https://apiwelcome.decowood.com/${query}`;
    } else {
      url = `https://apiwelcome2.decowood.com/${query}`;
    }
    
    const headers = new HttpHeaders();
    return this.http.get(url, { headers });
  }

  getProductos(termino: string) {
    this.lang = this.language.getLanguage();
    return this.getQuery(`${this.lang}/${termino}`, 1)
      .pipe(map(data => data));
  }

  getProducto(id: number) {
    this.lang = this.language.getLanguage();
    return this.getQuery(`${this.lang}/${id}`, 2)
      .pipe(map(data => data));
  }
}
