import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { DecowoodService } from '../../services/decowood.service';

@Component({
  selector: 'app-product-cell',
  templateUrl: './product-cell.component.html',
  styles: [
  ]
})
export class ProductCellComponent {
  @Input() items: any[] = [];
  producto: any = {};
  loadingProducto: boolean;
  loadingProduct: boolean;

  constructor(private router: Router,  private decowood: DecowoodService, private animateScrollService: NgAnimateScrollService) {
    }

    getProduct(id: number){
      this.loadingProducto = true;

      this.decowood.getProducto(id).subscribe(producto => {
         this.producto = producto;
         this.loadingProducto = false;
      });
    }

  verProducto( item: any ) {
    this.animateScrollService.scrollToElement('navbar',1000);
    let productoId;
    productoId = item.id;
    this.getProduct(productoId);
    //this.router.navigate([ '/producto', productoId  ]);

  }

}
