<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light" id="navbar">
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand font-weight-bold" href="#">DECOWOOD</a>
    <a class="navbar-user" href="#">Buscar</a>

    <div class="collapse navbar-collapse " id="navbarTogglerDemo03">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
                <a class="nav-link" href="#decowood">Decowood</a>
            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <input class="form-control mr-sm-2" type="Buscar" placeholder="Buscar" aria-label="Buscar">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Buscar</button>
        </form>
    </div> -->
    <a (click)="backHome()">
        <img src="https://decowood.com/img/decowood_logo.png" width="auto" height="auto">
     </a>
</nav>
