import { Component, OnInit } from '@angular/core';
import { DecowoodService } from '../../services/decowood.service';
import { ProductCellComponent } from '../product-cell/product-cell.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: [
  ]
})
export class SearchComponent {

  productos: any[] = [];

  loading: boolean;
  timeout: any;
  emptyInput: boolean;
  emptyProducts:boolean;
  mobile:boolean;
  constructor(private decowood: DecowoodService, public router: Router) { }

  ngOnInit(): void {
    if (window.screen.width < 400) { // 768px portrait
      this.mobile = true;
    }else{
      this.mobile = false;
    }
  }

  buscar(termino: string) {
    if(termino.length < 4) {
      this.emptyInput = true;
  
      if(termino.length==0){
        this.emptyInput=false;
      }

      return 
    }

    this.loading = false;
    if(this.timeout){
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.loading = true;
      this.decowood.getProductos( termino ).subscribe( (data: any) => {

            this.productos = data;
            this.emptyInput = false;
            this.loading = false;
            if(this.productos.length < 1 ){
              this.emptyProducts = true;
            }else{
              this.emptyProducts = false;
            }
          })
    }, 700);
    
  }

  public resetProducts(){
    this.loading = true;
  }


}
