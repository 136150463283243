<app-search></app-search>
<div class="pt-2 container">
  <div class="row">
    <div class="col-xs-12 col-md-12 mb-4"><h2><b>{{"Guía de compra sostenible." | translate}}</b></h2></div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Nuestro compromiso" | translate}}</b></p>
      <p>{{"Somos amantes de la naturaleza. Como tal, tenemos un gran compromiso con el medio ambiente y la sociedad, siendo conscientes de nuestro entorno y todo lo que le rodea. Es por eso que seleccionamos previamente los materiales a partir de fuentes sostenibles para después trabajarlas artesanalmente en nuestros talleres. " | translate}}</p>
      <p>{{"Apostamos por un enfoque diferente en el hogar, donde prima el estilo natural y mediterráneo junto al producto de proximidad. Queremos que cualquier estancia pueda inspirarte, que te enamore día a día y te comprometa a formar parte de una vida más sostenible." | translate}}</p>


    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Diseño y materiales" | translate}}</b></p>
      <p>{{"Nos encanta cuidar cada detalle en todo lo que hacemos y por eso, nuestros diseñadores buscan continuamente nuevas tendencias que se puedan adaptar a nuestro estilo y filosofía. Apostamos por productos que mezclan el estilo mediterráneo, sean funcionales y durables." | translate}}</p>
      <p>{{"Los productos con la etiqueta eco-friendly están elaborados con materiales que provienen de fuentes sostenibles, como puede ser la madera, y hechos a mano en el taller. Todos y cada uno de estos productos cumplen con un proceso exigente de garantía de calidad con el objetivo de hacerlo durable y resistente." | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <img class="img-fluid" src="../../../../assets/img/Sostenible-min.png" alt="instrucciones">
    </div>

  </div>
  <div class="m-4"></div>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <p><b>{{"Proceso y proveedores" | translate}}</b></p>
      <p>{{"Dentro de nuestro proceso eco-friendly, contamos con proveedores españoles que trabajan de forma artesanal y respetan nuestra filosofía, apostando por materiales sostenibles y/o reciclados. Producimos en talleres de proximidad que hacen posible que un diseño en papel se transforme en una realidad que enamore." | translate}}</p>


    </div>

  </div>

</div>


