<app-producto *ngIf="!loadingProducto" [producto]="producto"></app-producto>
<app-loading *ngIf="loadingProducto"></app-loading>
<h2 class="mt-3 font-weight-bold" *ngIf="items.length > 0" style="text-align: center;"> {{"Busca tus instrucciones" | translate}}. </h2>
<div class="card-columns m-5 animated fadeIn">

    <div (click)="verProducto( item )" *ngFor="let item of items" class="card puntero">
        <div class="product-img">
            <img class="card-img-top" [src]="item.img+'?width=400'" alt="Card image cap" >
        </div>

        <div class="card-body">
            <h5 class="card-title">{{ item.nombre }}</h5>
            <p class="card-text">
                <button (click)="verProducto( item )" class="btn btn-primary" id="boton-instrucciones" style="float: left;">
                    {{"Ver mas" | translate}} >>
                </button>
                <a [href]="item.instrucciones" class="btn btn-primary" target="_blank" id="boton-instrucciones" style="float: right;">
                    {{"Descargar" | translate}} >>
                </a>
            </p>
        </div>
    </div>
</div>
