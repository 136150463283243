<app-search></app-search>
<div class="pt-2 container ">

  <div class="row">
    <div class="col-xs-12 col-md-12 mb-4">
      <h2><b>{{"Guía de instrucciones." | translate}}</b></h2>
    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"¿Necesitas ayuda con para montar tu mueble?" | translate}}</b></p>
      <p>{{"En DECOWOOD te lo ponemos fácil para que tengas tu mueble listo para colocarlo en tu estancia favorita." | translate}}</p>
      <p>{{"Dentro del pedido, te habrá llegado una pequeña caja con un código QR: escanealo con tu móvil para poder ir a ver o descargar las instrucciones del producto directamente." | translate}}</p>
      <p>{{"Si no logras encontrarlo o no dispones de un lector de QR no te preocupes, escribe el nombre del producto que has comprado en el buscador y podrás ver o descargarte las instrucciones para tenerlas a mano en todo momento." | translate}}</p>


    </div>
    <div class="col-xs-12 col-md-6">
      <p><b>{{"¿Qué hago si me falta una pieza?" | translate}}</b></p>
      <p>{{"Antes de empezar, ponte en un espacio cómodo y asegúrate de tenerlo todo, así podremos evitar posibles problemas. Como sabes, nuestros productos los fabricamos a mano en España con mucho cariño. Si crees que te falta una pieza que aparece en las instrucciones, sólo tendrás que contactarnos y enviarnos un correo electrónico a info@decowood.es para que podamos gestionarlo lo antes posible y hacértelo llegar a casa." | translate}}</p>
      <p>{{"Si tienes alguna duda porque el mueble no ha quedado bien montado, no te preocupes. Te recomendamos que mires de nuevo las instrucciones o puedes contactarnos para que te ayudemos durante el proceso." | translate}}</p>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <img class="img-fluid" src="../../../../assets/img/Instrucciones.png" alt="instrucciones">
      </div>

    </div>
  </div>

</div>


