import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { SearchComponent } from '../../search/search.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  constructor(private navigation: Router,private zone:NgZone, private animateScrollService: NgAnimateScrollService, private search: SearchComponent) { }

  ngOnInit(): void {
  }

  backHome(){
    if(this.navigation.url=== '/'){
      this.zone.runOutsideAngular(() => {
        location.reload();
      });
    }else{
      this.navigation.navigate(['/']);

    }


  }
}
